import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function RuggedEdgeIcon() {
return (
<SvgIcon sx={{ height: 21, width: 100, mr: 2 }}>
<svg viewBox="113.8906 82.2813 180.4531 31.2969" xmlns="http://www.w3.org/2000/svg">  <text style={{fill: "#3E6549", fontWeight: 700, fontFamily: "Arial, sans,serif", fontSize: "28px"}} x="113.892" y="107.635">Rugged Edge</text>
</svg>
</SvgIcon>
);
}