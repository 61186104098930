import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import Products from './components/Products';
import FAQ from './components/FAQ';
import getLPTheme from './getLPTheme';
import Solutions from './components/Solutions';

export default function LandingPage() {
  const LPtheme = createTheme(getLPTheme("light"));
  return (
    <ThemeProvider theme={ LPtheme }>
      <CssBaseline />
      <AppAppBar />
      <Solutions />

      <Box sx={{ bgcolor: 'background.default' }}>
        <Products />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>

    </ThemeProvider>
  );
}
