import './App.css';
import LandingPage from "./landing-page/LandingPage.js"

function App() {
  return (
    <div className="App">
      <LandingPage style={{ Width: '100%', Height: '100%' }} />
    </div>
  );
}

export default App;
