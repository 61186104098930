import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
  boxShadow:'0 0 12px 8px hsla(137, 24%, 32%, 0.2)',
  outline: '1px solid',
  backgroundImage: 'url(images/REDSolutions.png)',
  overflowY: 'hidden ! important',
  overflowX: 'hidden ! important',
  backgroundColor: 'white',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  outlineColor:'hsla(137, 24%, 32%, 0.5)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0),
    height: 700,
  },
}));

export default function Solutions() {
  return (
    <Box
      id="solutions"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(137, 24%, 32%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl137, 24%, 32%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          marginTop: '0px',
        }}
      >
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
