import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Footer() {

  return (
    <Container

      id="contact"
      sx={{
        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 1 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '80%' }, textAlign: "center"}}>
            <a href='https://www.greengeeks.com' alt='GreenGeeks' rel='nofollow' sx={{textAlign: "center"}}><img src='https://greengeeks.com/includes/images/green-tags/Green_12.png' border='0' alt='' /></a>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Site
          </Typography>
          <Link color="text.secondary" href="#solutions">
            Solutions
          </Link>
          <Link color="text.secondary" href="#products">
            Products
          </Link>
          <Link color="text.secondary" href="#faq">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link color="text.secondary" href="about.html">
            About Us
          </Link>
          <Link color="text.secondary" href="mailto:info@ruggededge.co">
            Contact Us
          </Link>

        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
         
        </Box>
      </Box>
      <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
         
        </Box>
    </Container>
  );
}